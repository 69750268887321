import {
  ActionFunctionArgs,
  json,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";
import {
  useFetcher,
  useLoaderData,
  useRouteLoaderData,
} from "@remix-run/react";
import { useCallback, useEffect, useState } from "react";
import { getFormDataFromSearchParams, parseFormData } from "remix-hook-form";

import { AutocompleteOption } from "~/components/molecules/SearchBar";
import HighlightCardList from "~/components/organisms/HighlightCardList/HighlightCardList";
import Masthead from "~/components/organisms/Masthead";
import MastheadMobile from "~/components/organisms/MastheadMobile";
import Sections from "~/components/sections";
import SectionProjectTopWithGallery from "~/components/sections/SectionProjectTopWithGallery";
import { IProject } from "~/entities/project";
import { IPromotionBanner } from "~/entities/promotion";
import { ISection } from "~/entities/section";
import { getAutocompleteProject } from "~/services/autocompleteService";
import {
  getProjects,
  getPromotionBannersBySection,
  getSections,
} from "~/services/projectService";
import { IPaginateResponseData, IPaginationReq } from "~/types";
import { IConfig } from "~/types/config";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { LIMIT, PAGE } from "~/utilities/constants/common";
import { HIGHLIGHT_NAVIGATOR } from "~/utilities/constants/highlightNavigator";
import { REQUEST_KEYS, REQUEST_NAME } from "~/utilities/constants/requestKey";
import { ELayout } from "~/utilities/enums/Layouts";
import { Slug } from "~/utilities/enums/Slug";
import { getMetadata } from "~/utilities/helpers/metadata";
import { parseSearchParams } from "~/utilities/helpers/queryString";
import { extractDomainFromRequest } from "~/utilities/helpers/requestHelper";

export type THomeLoaderData = {
  siteUrl: string;
  config?: IConfig;
  promotionBanners: IPromotionBanner[];
  sections: IPaginateResponseData<ISection>;
  featuredProjects: IPaginateResponseData<IProject>;
  optionsAutocomplete?: IPaginateResponseData<unknown>;
  sectionFeaturedLaunches?: ISection;
};

export const meta: MetaFunction<typeof loader> = (args) => {
  const { data, matches } = args;

  const rootMatch = matches?.find((match) => match?.id === "root");

  if (!rootMatch) {
    return [];
  }

  const { metadata } = rootMatch.data as IRouteLoaderData;

  if (!data) {
    return [];
  }

  const { siteUrl } = { ...data };

  const robots = "index,follow";

  return getMetadata({
    title: metadata?.title,
    description: metadata?.description,
    siteUrl,
    image: metadata?.ogImage,
    twitterCreator: metadata?.twitterCreator,
    twitterSite: metadata?.twitterSite,
    additionalMeta: [
      { name: "og:type", content: "website" },
      {
        name: "og:site_name",
        content: metadata?.title,
      },
      { name: "robots", content: robots },
      { name: "googlebot", content: robots },
    ],
  });
};

export async function loader({ request }: LoaderFunctionArgs) {
  const requestUrl = new URL(request.url);
  const domain = extractDomainFromRequest(request);

  const featuredProjects = await getProjects({
    domain,
    searchParams: {
      featured: true,
      page: Number(PAGE),
      limit: Number(LIMIT),
    },
  });

  const sectionFeaturedLaunches: ISection = {
    id: "featured-launches",
    name: "Featured Launches",
    slug: Slug.FEATURED_LAUNCHES,
    background: "transparent",
    projects: featuredProjects?.items || [],
    layout: ELayout.SECTION_PROJECT_TOP_WITH_GALLERY,
  };

  const siteUrl = requestUrl.protocol + "//" + requestUrl.host;

  const searchParams = getFormDataFromSearchParams(request) as IPaginationReq;

  const queryParsed = parseSearchParams(requestUrl.searchParams);

  let promotionBanners: IPromotionBanner[] = [];
  if (searchParams?.sectionId) {
    const promotionBannersResponse: IPaginateResponseData<IPromotionBanner> =
      await getPromotionBannersBySection(searchParams?.sectionId || "", domain);
    promotionBanners = promotionBannersResponse?.items || [];
  }

  let optionsAutocomplete;

  if (
    queryParsed?.[REQUEST_KEYS.TRIGGER] === REQUEST_NAME.AUTOCOMPLETE_OPTIONS
  ) {
    const response = await getAutocompleteProject({
      domain,
      searchParams: {
        page: queryParsed?.autocompleteParams?.page,
        limit: queryParsed?.autocompleteParams?.limit,
        name: queryParsed?.autocompleteParams?.search,
      },
    });

    const convertedDataAutocomplete: AutocompleteOption[] =
      response?.items?.map((item) => ({
        ...item,
        label: item.projectName,
        value: item.projectSlug,
      }));

    optionsAutocomplete = {
      ...response,
      items: convertedDataAutocomplete,
    };
  }

  const sections = await getSections({
    domain,
    pagination: {
      page: searchParams?.page || PAGE,
      limit: searchParams?.limit || LIMIT,
    },
  });

  const loaderData: THomeLoaderData = {
    siteUrl,
    sections,
    promotionBanners,
    featuredProjects,
    optionsAutocomplete,
    sectionFeaturedLaunches,
  };

  return json(loaderData);
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await parseFormData<Record<string, string>>(request, false);
  const url = new URL(request.url);

  Object.keys(formData)?.forEach((key) => {
    if (
      formData[key] !== "" &&
      formData[key] !== undefined &&
      formData[key] !== null &&
      formData[key] !== "null"
    ) {
      return url.searchParams.set(key, formData[key]);
    }
    return url.searchParams.delete(key);
  });
  const params = url.searchParams.toString();
  return redirect(`${Slug.PROJECTS}?${params}`);
}

export default function Index() {
  const {
    sections: serverSections,
    featuredProjects,
    sectionFeaturedLaunches,
  } = useLoaderData() as THomeLoaderData;

  const { config } = useRouteLoaderData("root") as IRouteLoaderData;
  const fetcher = useFetcher<THomeLoaderData>();
  const [sections, setSections] = useState<ISection[]>(serverSections?.items);
  const [currentPage, setCurrentPage] = useState<number>(
    serverSections?.currentPage
  );

  const onFetchMoreSections = useCallback(() => {
    if (
      fetcher.state === "loading" ||
      currentPage === fetcher?.data?.sections?.totalPage
    ) {
      return;
    }

    const newPage = currentPage + 1;
    if (newPage > serverSections?.totalPage) return;
    fetcher.load(`/?page=${newPage}`);

    setCurrentPage(newPage);
  }, [fetcher.state]);

  useEffect(() => {
    if (
      !fetcher.data ||
      fetcher.state === "loading" ||
      currentPage === fetcher?.data?.sections?.totalPage
    ) {
      return;
    }

    if (fetcher.data) {
      const newItems = (fetcher?.data?.sections?.items || []) as ISection[];
      setSections((prevSections) => [...prevSections, ...newItems]);
    }
  }, [fetcher.data, currentPage, fetcher.state]);

  return (
    <main className="overflow-hidden">
      {config && <Masthead image={config.coverImage} />}
      {config && <MastheadMobile image={config.mobileMastheadImage} />}
      <HighlightCardList cards={HIGHLIGHT_NAVIGATOR} />
      {/* {!isEmpty(featuredProjects?.items) && (
        <>
          <FeaturedLaunches
            title="Featured Launches"
            projects={featuredProjects?.items}
          >
            {featuredProjects.items?.map((project, index) => (
              <CardFeature
                key={project?.id + index}
                project={project}
                className="mx-5"
                thumbnailClass="max-h-[196px]"
              />
            ))}
            </FeaturedLaunches>
            

          <FeaturedLaunchesMobile
            title="Featured Launches"
            projects={featuredProjects?.items}
          >
            {featuredProjects?.items?.map((project, index) => (
              <CardFeature
                key={project?.id + index}
                project={project}
                className="last:mb-6"
                thumbnailClass="max-h-[176px]"
              />
            ))}
          </FeaturedLaunchesMobile>
        </>
      )} */}
      {sectionFeaturedLaunches && (
        <SectionProjectTopWithGallery data={sectionFeaturedLaunches} />
      )}
      <Sections
        sections={sections}
        infinitiLoad
        isLoading={fetcher.state === "loading"}
        onLoadMore={onFetchMoreSections}
      />
    </main>
  );
}
