import isEmpty from "lodash/isEmpty";
import { useRef, useState } from "react";
import { SwiperOptions } from "swiper/types";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import { Swiper, SwiperSlide } from "~/components/atoms/Swiper";
import { ForwardedSwiper } from "~/components/atoms/Swiper/Swiper";
import Typo from "~/components/atoms/Typo";
import CardFeature from "~/components/molecules/CardFeature";
import LinkViewAll from "~/components/molecules/LinkViewAll";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SwiftCardsMobileProps {
  projects: any[];
  name: string;
  link: string;
}

export default function SwiftCardsMobile({
  projects,
  link,
  name,
}: SwiftCardsMobileProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperOptions: SwiperOptions = {
    slidesPerView: 1,

    on: {
      slideChange: (swiper) => {
        const { activeIndex } = swiper;
        setActiveIndex(activeIndex);
      },
    },
  };
  const swiperRef = useRef<ForwardedSwiper | null>(null);

  return (
    <div className="swiper__SwiftCardsMobile flex flex-col gap-2">
      <Typo
        tag={ETypoTag.DIV}
        variant={ETypoVariant.HEADER_20}
        className="container break-words font-bold"
      >
        <div dangerouslySetInnerHTML={{ __html: name }} />
      </Typo>
      {!isEmpty(projects) && (
        <Swiper
          ref={swiperRef}
          className="flex w-full flex-row items-stretch"
          {...swiperOptions}
        >
          {projects.map((item) => {
            return (
              <SwiperSlide
                className="group h-auto min-h-max w-full px-6 py-5"
                key={item.id}
              >
                <CardFeature
                  project={item}
                  className="block h-full w-full"
                  thumbnailClass="max-h-[176px]"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      {/* PAGINATION */}

      <div className="container flex flex-row items-center justify-center">
        {!isEmpty(projects) && (
          <div className="space-x-3 lg:flex lg:w-full">
            <Button
              size="wide"
              variant={activeIndex === 0 ? "disabled" : "default"}
              onClick={() => {
                swiperRef?.current?.core?.swiper?.slidePrev();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowLeftIcon} height={40} width={20} />
            </Button>
            <Button
              size="wide"
              variant={
                activeIndex === projects.length - 1 ? "disabled" : "default"
              }
              onClick={() => {
                swiperRef?.current?.core?.swiper?.slideNext();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowRightIcon} height={40} width={20} />
            </Button>
          </div>
        )}
        <LinkViewAll slug={link} className="hidden justify-center lg:flex" />
      </div>
    </div>
  );
}
