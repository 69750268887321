import { motion } from "framer-motion";
import { useState } from "react";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import CardFeature from "~/components/molecules/CardFeature";
import LinkViewAll from "~/components/molecules/LinkViewAll";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { EIcon } from "~/utilities/enums/Icons";

export default function SwiftCards({
  projects,
  link,
}: {
  projects: IProject[];
  link: string;
}) {
  const CARD_OFFSET = 10;
  const SCALE_FACTOR = 0.08;
  const THRESHOLD = 200;

  const [dragStartFrom, setDragStartFrom] = useState({
    x: 0,
    y: 0,
  });

  const [isDrag, setIsDrag] = useState(false);

  const [cards, setCards] = useState(projects.slice(0, 3));

  const handleMoveCardPrev = () => {
    const cardPrev = cards[cards.length - 1];
    setCards([cardPrev, ...cards.slice(0, cards.length - 1)]);
  };

  const handleMoveCard = () => {
    setCards([...cards.slice(1), cards[0]]);
  };

  return (
    <div className="flex h-[580px] gap-[32px]">
      <div className="">
        <div className="flex h-full flex-col justify-between">
          <div className="mt-8">
            <LinkViewAll slug={link} />
          </div>
          <div className="space-x-3">
            <Button
              size="wide"
              onClick={() => {
                handleMoveCardPrev();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowLeftIcon} height={40} width={20} />
            </Button>
            <Button
              size="wide"
              onClick={() => {
                handleMoveCard();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowRightIcon} height={40} width={20} />
            </Button>
          </div>
        </div>
      </div>
      <ul className="relative  mt-auto h-[320px] flex-1">
        {cards.map((item, index) => {
          const bgOpa = (index + 1) * 25;
          return (
            <motion.li
              key={item?.id || index}
              style={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                "--opacity": bgOpa > 1 ? 0.75 : bgOpa,
              }}
              className={cn(
                "absolute bottom-0 right-0 h-fit w-fit origin-bottom-left rounded-xl",
                index === 0 && "cursor-grab"
              )}
              animate={{
                zIndex: cards.length - index,
                rotate: `${-index * CARD_OFFSET}deg`,
              }}
              drag={index === 0 ? "x" : false}
              dragConstraints={{
                left: 0,
                right: 0,
              }}
              onDragStart={(e: PointerEvent) => {
                setDragStartFrom({
                  x: e.x,
                  y: e.y,
                });
                setIsDrag(true);
              }}
              whileDrag={{
                rotate: `-25deg`,
                scale: 1 - SCALE_FACTOR,
                transition: {
                  duration: 0.6,
                },
              }}
              transition={{
                duration: 0.3,
              }}
              onDragEnd={(e: PointerEvent) => {
                const x = e.x - dragStartFrom.x;
                if (x > THRESHOLD) {
                  handleMoveCard();
                }
                if (x < -THRESHOLD) {
                  handleMoveCardPrev();
                }
                setIsDrag(false);
              }}
            >
              <CardFeature
                project={item}
                full={false}
                className={cn(
                  "max-w-[580px]",
                  isDrag && "pointer-events-none select-none",
                  index !== 0 &&
                    "overlay after:bg-color/[var(--opacity)] pointer-events-none select-none"
                )}
                unitTypeContainerClass="min-h-[130px]"
                thumbnailClass={cn(
                  "pointer-events-none select-none",
                  "max-h-[196px]"
                )}
                draggable={isDrag}
              />
            </motion.li>
          );
        })}
      </ul>
    </div>
  );
}
