import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SwiperOptions } from "swiper/types";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import { Swiper } from "~/components/atoms/Swiper";
import { ForwardedSwiper } from "~/components/atoms/Swiper/Swiper";
import { SwiperSlide } from "~/components/atoms/Swiper/SwiperSlide";
import Typo from "~/components/atoms/Typo";
import CardBanner from "~/components/molecules/CardBanner";
import {
  MAX_WIDTH_CONTAINER,
  PADDING_MEDIUM_SCREEN,
  PADDING_SMALL_SCREEN,
} from "~/components/organisms/BannerPromotion/utils";
import { IPromotionBanner } from "~/entities/promotion";
import useMediaQuery from "~/hooks/use-mediaquery";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface BannerPromotionProps {
  heading?: string;
  background?: string;
  promotions: IPromotionBanner[];
}

const BannerPromotion: React.FC<BannerPromotionProps> = ({
  heading,
  background,
  promotions,
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [swiperKey, setSwiperKey] = useState<number>(0);
  const swiperRef = useRef<ForwardedSwiper | null>(null);
  const isLargeScreen = useMediaQuery("lg");

  useEffect(() => {
    const updateOffset = () => {
      const padding =
        window.innerWidth > MAX_WIDTH_CONTAINER
          ? PADDING_MEDIUM_SCREEN
          : PADDING_SMALL_SCREEN;

      const calculatedOffset =
        window.innerWidth > MAX_WIDTH_CONTAINER
          ? (window.innerWidth - MAX_WIDTH_CONTAINER) / 2
          : padding;

      setOffset(calculatedOffset);
    };

    updateOffset();
    window.addEventListener("resize", updateOffset);

    return () => {
      window.removeEventListener("resize", updateOffset);
    };
  }, []);

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [offset]);

  const options: SwiperOptions = {
    slidesPerView: "auto",
    slidesOffsetBefore: offset,
    slidesOffsetAfter: offset,
    initialSlide: 0,
    spaceBetween: isLargeScreen ? 24 : 32,
    setWrapperSize: true,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    on: {
      slideChange: (swiper) => {
        const { activeIndex } = swiper;
        setActiveIndex(activeIndex);
      },
    },
  };

  return (
    <div className="flex flex-col gap-8 lg:gap-5" style={{ background }}>
      <div className="container flex flex-row items-center justify-between">
        {!heading && (
          <Typo
            color={ETypoColor.TEXT}
            className="font-semibold lg:text-header-20"
            tag={ETypoTag.H3}
            variant={ETypoVariant.HEADER_32}
          >
            {t("promotion_banner")}
          </Typo>
        )}
        {!!heading && (
          <Typo
            tag={ETypoTag.DIV}
            variant={ETypoVariant.HEADER_32}
            className="break-words font-semibold lg:text-header-20"
          >
            <div
              dangerouslySetInnerHTML={{ __html: heading }}
              className="ql-editor"
            />
          </Typo>
        )}

        {!!promotions?.length && (
          <div className="space-x-3">
            <Button
              size={isLargeScreen ? "wideMobile" : "wide"}
              variant={activeIndex === 0 ? "disabled" : "default"}
              onClick={() => {
                swiperRef?.current?.core?.swiper?.slidePrev();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowLeftIcon} height={40} width={20} />
            </Button>
            <Button
              size={isLargeScreen ? "wideMobile" : "wide"}
              variant={
                activeIndex === promotions.length - 1 ? "disabled" : "default"
              }
              onClick={() => {
                swiperRef?.current?.core?.swiper?.slideNext();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowRightIcon} height={40} width={20} />
            </Button>
          </div>
        )}
      </div>
      <div className="relative">
        {offset > 0 && (
          <Swiper
            key={swiperKey}
            className="overflow-initial"
            defaultValue={activeIndex}
            ref={swiperRef}
            {...options}
          >
            {promotions?.map((promotion, index) => (
              <SwiperSlide
                key={promotion.id + index}
                className="h-[360px] w-fit cursor-pointer lg:h-[146px] lg:w-[261px]"
              >
                <CardBanner
                  promotion={promotion}
                  className="h-[360px] rounded-3xl lg:h-[146px] lg:rounded-lg"
                  imageClass="lg:h-[146px] h-[360px] rounded-3xl lg:rounded-lg"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default BannerPromotion;
