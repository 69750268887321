import isEmpty from "lodash/isEmpty";
import { ReactNode, useEffect, useState } from "react";

import Typo from "~/components/atoms/Typo";
import LinkViewAll from "~/components/molecules/LinkViewAll";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface GalleryThreeProps {
  children?: ReactNode;
  className?: string;
  title: string;
  link: string;
  projects: IProject[];
}

const GalleryThree: React.FC<GalleryThreeProps> = (props) => {
  const { children, className, title, link } = props;
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    if (!isEmpty(props?.projects)) {
      setProjects(props?.projects);
    }
  }, [props?.projects]);

  return (
    <div className={cn("flex flex-col gap-8 lg:gap-6", className)}>
      <div className="container flex items-center justify-between gap-6">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: title }}
            className="ql-editor"
          />
        </Typo>
        {projects && projects.length > 2 && (
          <LinkViewAll slug={link} className="lg:hidden" />
        )}
      </div>
      {children}
      {projects && projects.length > 2 && (
        <LinkViewAll
          slug={link}
          className="container hidden justify-center lg:flex"
        />
      )}
    </div>
  );
};

export default GalleryThree;
