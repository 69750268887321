import isEmpty from "lodash/isEmpty";
import { SwiperOptions } from "swiper/types";

import { Swiper, SwiperSlide } from "~/components/atoms/Swiper";
import Typo from "~/components/atoms/Typo";
import CardFeature from "~/components/molecules/CardFeature";
import LinkViewAll from "~/components/molecules/LinkViewAll";
import { cn } from "~/utilities/cn";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SlideCardsProps {
  projects: any[];
  name: string;
  link: string;
}

export default function SlideCards({ projects, name, link }: SlideCardsProps) {
  const swiperOptions: SwiperOptions = {
    slidesPerView: "auto",
    centeredSlides: true,
    initialSlide: projects?.length > 1 ? 1 : 0,
    pagination: {
      clickable: true,
      el: ".swiper-pagination-swiper__slideCards",
    },
  };

  return (
    <div
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        "--padding-top-card": "90px",
      }}
      className="swiper__slideCards"
    >
      <div className="container mb-6 flex items-center justify-between">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: name }}
            className="ql-editor"
          />
        </Typo>
        <LinkViewAll slug={link} />
      </div>
      <Swiper className="-mb-[var(--padding-top-card)]" {...swiperOptions}>
        {!isEmpty(projects) &&
          projects.map((item) => {
            return (
              <SwiperSlide
                className="group  w-full max-w-[688px]"
                key={item.id}
              >
                <div
                  className={cn(
                    "overflow-hidden px-3 py-[var(--padding-top-card)] transition-transform",
                    projects?.length > 1
                      ? "group-[&:not(.swiper-slide-active)]:-translate-y-[12%] group-[.swiper-slide-next]:-skew-y-12 group-[.swiper-slide-prev]:skew-y-12"
                      : ""
                  )}
                >
                  <CardFeature
                    project={item}
                    full={false}
                    unitTypeContainerClass="min-h-[132px]"
                    className={cn(
                      "overlay w-full max-w-[688px] hover:shadow-card-swiper-hover",
                      projects?.length > 1
                        ? "after:from-darkGrey40 after:to-darkGrey group-[.swiper-slide-next]:after:bg-gradient-to-r group-[.swiper-slide-prev]:after:bg-gradient-to-l"
                        : ""
                    )}
                    thumbnailClass="max-h-[196px]"
                  />
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {/* PAGINATION */}
      <div className="swiper-pagination-swiper__slideCards relative z-[50] mt-6 flex w-full  flex-row justify-center gap-x-3 *:size-3 *:rounded-full *:bg-gableGreen25 [&>.swiper-pagination-bullet-active]:bg-gableGreen75"></div>
    </div>
  );
}
